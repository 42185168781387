<template>
  <BModal
    id="contract-modal"
    centered
    no-close-on-backdrop
    size="lg"
    :cancel-title="$t('client.cancel')"
    cancel-variant="danger"
    ok-only
    ok-title="Да"
    @show="openModal"
  >
    <div class="d-flex justify-content-between align-items-center">
      <div class="flex-grow-1">
        <label for="">Файл :</label>
        <BFormFile
          :browse-text="$t('upload')"
          :placeholder="$t('choose_file_or_drop')"
          :drop-placeholder="$t('choose_file_or_drop')"
          v-model="file"
        />
      </div>
      <div class="mt-2 ml-1">
        <b-button variant="success" :disabled="!file" @click="uploadFile">
          {{ $t('upload') }}
        </b-button>
      </div>
    </div>

    <div
      v-if="GET_ATTACHMENTS && GET_ATTACHMENTS.results.length"
      class="
      mt-5
              d-flex
              align-items-center
              justify-content-between
              px-1
              text-center
              
            "
    >
      <h5>{{ $t('objects.title') }}</h5>
      <h5>{{ $t('actions') }}</h5>
    </div>

    <div
      no-body
      class="p-1"
      v-for="file in GET_ATTACHMENTS.results"
      :key="file.id"
    >
      <div class="d-flex align-items-center justify-content-between">
        <p class="file-text mr-2 mb-0 file-name">
          {{ getFileNameFromUrl(file.file) }}
        </p>
        <div class="d-flex">
          <b-button variant="success" size="sm" @click="setFile(file)">
            <feather-icon icon="EyeIcon" />
          </b-button>
          <b-button
            class="mx-50"
            variant="primary"
            size="sm"
            @click="downloadFile(file.file)"
          >
            <feather-icon icon="DownloadIcon" />
          </b-button>

          <b-button
            v-if="GET_ATTACHMENTS.results.length > 1"
            variant="danger"
            size="sm"
            @click="deleteFile(file.id)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </div>
      </div>
    </div>

    <SeeDocModal :document="{ file: targetFile }" />
  </BModal>
</template>

<script>
import { BModal, BFormFile, BButton } from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import { getUserData } from '@/auth/utils'

import SeeDocModal from '@/components/SeeDocModal.vue'

export default {
  components: {
    BModal,
    BFormFile,
    BButton,

    SeeDocModal,
  },
  props: {
    propsItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      file: null,
      targetFile: {},
    }
  },

  mounted() {
    // this.FETCH_ATTACHMENT_FILES({
    //   content_type: this.propsItem.content_type,
    //   object_id: this.propsItem.id,
    // })
  },

  computed: {
    ...mapGetters('taskManager', ['GET_ATTACHMENTS']),
  },

  methods: {
    ...mapActions('taskManager', [
      'FETCH_ATTACHMENT_FILES',
      'UPLOAD_ATTACHMENT_FILE',
      'DELETE_ATTACHMENT_FILE',
    ]),

    openModal() {
      this.FETCH_ATTACHMENT_FILES({
        content_type: this.propsItem.content_type,
        object_id: this.propsItem.id,
      })
    },

    downloadFile(url) {
      // console.log('URL', url)
      fetch(url).then(function(t) {
        return t.blob().then(b => {
          var a = document.createElement('a')
          a.href = URL.createObjectURL(b)
          a.setAttribute('download', 'документ')
          a.click()
        })
      })
    },

    getFileNameFromUrl(file) {
      let fileName = file.split('/')
      fileName = fileName[fileName.length - 1]
      return fileName
    },

    setFile(item) {
      this.targetFile = item.file

      this.$nextTick(() => {
        this.$bvModal.show('see-doc-modal')
      })
    },

    deleteFile(id) {
      this.$_showAreYouSureModal().then(response => {
        if (response) {
          this.DELETE_ATTACHMENT_FILE(id)
            .then(() => {
              this.$_successToast(this.$t('general.successfully_removed'))
              this.FETCH_ATTACHMENT_FILES({
                content_type: this.propsItem.content_type,
                object_id: this.propsItem.id,
              })
            })
            .catch(() => {
              this.$_errorToast(this.$t('general.error'))
            })
        }
      })
    },

    async uploadFile() {
      if (!this.file) {
        return
      }
      try {
        const formData = new FormData()
        let userId = getUserData().id
        formData.append('file', this.file)
        formData.append('content_type', this.propsItem.content_type)
        formData.append('object_id', this.propsItem.id)
        formData.append('author', userId)

        await this.UPLOAD_ATTACHMENT_FILE(formData)
        this.FETCH_ATTACHMENT_FILES({
          content_type: this.propsItem.content_type,
          object_id: this.propsItem.id,
        })

        this.file = null
        this.$_successToast(this.$t('general.success'))
      } catch (err) {
        console.log(err)
        this.$_errorToast(this.$t('general.error'))
      }
    },
  },
}
</script>

<style></style>
