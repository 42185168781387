<template>
  <div>
    <BRow>
      {{ params }}
      <BCol md="2">
        <AppSearchInput
          @callback="$emit('changeFilter', 'search', $event)"
          :placeholder="$t('search')"
        />
      </BCol>
      <BCol md="3">
        <b-form-group>
          <label for=""> Плательщик :</label>
          <v-select
            label="title"
            placeholder="Выберите плательщик"
            v-model="params.payer"
            :options="GET_PAYERS_LIST"
            @input="$emit('changeFilter', 'payer', $event)"
            :reduce="p => p.id"
          />
        </b-form-group>
      </BCol>
      <BCol md="3">
        <b-form-group>
          <label for=""> Контрагенты :</label>
          <v-select
            label="title"
            placeholder="Выберите контрагенты"
            v-model="params.contractor"
            :options="GET_CONTRACTORS_LIST"
            @input="$emit('changeFilter', 'contractor', $event)"
            :reduce="p => p.id"
          />
        </b-form-group>
      </BCol>
      <BCol md="4" class="d-flex justify-content-end  align-items-center">
        <b-button variant="primary" @click="$emit('addData')" class="mt-2">
          <feather-icon icon="PlusIcon" />
          {{ $t('add') }}
        </b-button>
      </BCol>
    </BRow>
  </div>
</template>

<script>
import { BRow, BCol, BButton, BFormGroup, VBTooltip } from 'bootstrap-vue'
import AppSearchInput from '@/components/AppSearchInput.vue'
import vSelect from 'vue-select'
import { getUserData } from '@/auth/utils'
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    vSelect,
    AppSearchInput,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      params: {
        page: 1,
        page_size: 10,
        search: '',
        payer: null,
        contractor: null,
      },
    }
  },
  computed: {
 
  },
  mounted() {
    this.params = { ...this.filter }

    this.FETCH_CONTRACTORS_LIST()
    this.FETCH_PAYERS_LIST({ user_id: getUserData().id })
  },

  methods: {
 
  },
}
</script>


