<template>
  <b-modal
    id="add-contract-modal"
    centered
    :title="getTitle"
    no-close-on-backdrop
    @show="openModal"
    size="lg"
    :cancel-title="$t('client.cancel')"
    cancel-variant="danger"
    :ok-title="editItem.id ? $t('change') : $t('save')"
    :ok-variant="editItem.id ? 'warning' : 'success'"
    @ok="onSubmit"
  >
    <validation-observer ref="ContractList">
      <b-row>
        <b-col md="8">
          <b-form-group label="Наименование: " class="my-1">
            <validation-provider
              #default="{ errors }"
              name="наименование "
              rules="required"
            >
              <b-form-input
                v-model="formData.title"
                placeholder="Введите наименование "
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="Номер договора : " class="my-1">
            <validation-provider
              #default="{ errors }"
              name="номер договор  "
              rules="required"
            >
              <b-form-input
                v-model="formData.number"
                type="number"
                placeholder="Введите номер договора  "
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <b-form-group label="Плательщик: " class="my-1">
        <validation-provider
          #default="{ errors }"
          name="плательщик "
          rules="required"
        >
          <v-select
            v-model="formData.payer"
            :options="GET_PAYERS_LIST"
            :reduce="item => item.id"
            label="title"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-row>
        <b-col md="6">
          <b-form-group label="Контрагент : ">
            <validation-provider
              #default="{ errors }"
              name="контрагент  "
              rules="required"
            >
              <v-select
                v-model="formData.contractor"
                :options="GET_CONTRACTORS_LIST"
                :reduce="item => item.id"
                label="title"
                @input="fetchSettlementAccount($event)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group label="Расчетный счет контрагента: ">
            <!-- <validation-provider
              #default="{ errors }"
              name="Расчетный счет контрагента "
              rules="required"
            > -->
            <v-select
              v-model="formData.settlement_account"
              :options="GET_SETTLEMENT_ACCOUNT_BY_CONTRACTOR"
              :reduce="item => item.id"
              label="title"
              :disabled="!formData.contractor"
            />
            <!-- <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider> -->
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group label="Назначение платежа: ">
            <validation-provider
              #default="{ errors }"
              name="назначение платежа  "
              rules="required"
            >
              <b-form-input v-model="formData.payment_purpose" placeholder="" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Тип счета: ">
            <v-select
              v-model="formData.bill_type"
              :options="GET_BILL_TYPE_LIST"
              :reduce="item => item.id"
              label="title"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-form-group label="Город: " class="my-1">
        <validation-provider
          #default="{ errors }"
          name="город "
          rules="required"
        >
          <v-select
            v-model="formData.city"
            :options="GET_CITIES_LIST"
            :reduce="item => item.id"
            label="title"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <BRow>
        <BCol md="6">
          <b-form-group label="Дата начала: ">
            <validation-provider
              #default="{ errors }"
              name="дата начала"
              rules="required"
            >
              <!-- <datepicker
                id="дате"
                :bootstrap-styling="true"
                :clear-button="true"
                :language="ru"
                :calendar-button="true"
                format="dd.MM.yyyy"
                :placeholder="$t('administration.date_of_issue')"
                calendar-button-icon="fa fa-calendar"
                clear-button-icon="fa fa-times"
                v-model="formData.start_date"
                :calendar-class="$_theme_layout + '_layout_datepicker'"
                :input-class="$_theme_layout === 'light' ? 'bg-white' : ''"
                @input="formData.start_date = customFormatter($event)"
              /> -->

              <input
                id="дате"
                type="date"
                @input="e => formData.start_date = e.target.value"
                class="form-control"
                :calendar-class="$_theme_layout + '_layout_datepicker'"
                :placeholder="$t('administration.date_of_issue')"
                :input-class="$_theme_layout === 'light' ? 'bg-white' : ''"
                clear-button-icon="fa fa-times"
                calendar-button-icon="fa fa-calendar"
                v-model="formData.start_date"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </BCol>
        <BCol md="6">
          <b-form-group label="Дата окончания: ">
            <!-- <datepicker
              id="дате"
              :bootstrap-styling="true"
              :clear-button="true"
              :language="ru"
              :calendar-button="true"
              format="dd.MM.yyyy"
              :placeholder="$t('administration.date_of_issue')"
              calendar-button-icon="fa fa-calendar"
              clear-button-icon="fa fa-times"
              v-model="formData.end_date"
              :calendar-class="$_theme_layout + '_layout_datepicker'"
              :input-class="$_theme_layout === 'light' ? 'bg-white' : ''"
              @input="formData.end_date = customFormatter($event)"
            /> -->
            <input
                id="дате"
                type="date"
                @input="e => formData.end_date = e.target.value"
                class="form-control"
                :calendar-class="$_theme_layout + '_layout_datepicker'"
                :placeholder="$t('administration.date_of_issue')"
                :input-class="$_theme_layout === 'light' ? 'bg-white' : ''"
                clear-button-icon="fa fa-times"
                calendar-button-icon="fa fa-calendar"
                v-model="formData.end_date"
              />
          </b-form-group>
        </BCol>
        <BCol md="6">
          <b-form-group label="День оплаты : ">
            <validation-provider
              #default="{ errors }"
              name="инн"
              rules="integer"
            >
              <cleave
                v-model="formData.payment_day"
                class="form-control"
                :options="moneyOptions"
                tabindex="5"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </BCol>
        <BCol md="6">
          <b-form-group label="Месячный лимит: ">
            <validation-provider
              #default="{ errors }"
              name="месячный лимит"
              rules="required"
            >
              <cleave
                v-model="formData.monthly_limit"
                :options="moneyOptions"
                class="form-control"
                placeholder="Введите месячный лимит"
                tabindex="5"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </BCol>

        <BCol md="4" class="d-flex align-items-center ">
          <BFormGroup label="Статус: ">
            <b-form-checkbox
              v-model="formData.is_active"
              name="check-button"
              class="custom-control-primary"
              switch
              inline
            />
          </BFormGroup>
        </BCol>
        <BCol md="4" class="d-flex align-items-center">
          <BFormGroup label="Активировать автоодобрение: ">
            <b-form-checkbox
              v-model="formData.is_auto_confirmable"
              name="check-button"
              class="custom-control-primary"
              switch
              inline
            />
          </BFormGroup>
        </BCol>

        <BCol md="4" class="d-flex align-items-center justify-content-end ">
          <div>
            <BButton
              variant="primary"
              @click="$bvModal.show('add-file-contract-modal')"
              class="px-2"
            >
              <feather-icon icon="PenToolIcon" />
              Файл
            </BButton>
            <div>
              <small v-if="files.length == 0 && isSend" class="text-danger"
                >Файлы обязательно</small
              >
            </div>
          </div>
        </BCol>
      </BRow>
    </validation-observer>
    <B-Modal
      id="add-file-contract-modal"
      centered
      title="Добавить файл"
      no-close-on-backdrop
      ok-title="Да"
      ok-only
    >
      <!-- size="lg" -->
      <!-- :cancel-title="$t('client.cancel')" -->
      <!-- :ok-title="editItem.id ? $t('change') : $t('save')"
      :ok-variant="editItem.id ? 'warning' : 'success'" @ok="onSubmit"
      @show="openModal" -->
      <!-- {{ files }} -->
      <div>
        <label for="">Файл :</label>
        <BFormFile
          :browse-text="$t('upload')"
          :placeholder="$t('choose_file_or_drop')"
          :drop-placeholder="$t('choose_file_or_drop')"
          v-model="file"
          @input="uploadFile"
        />
      </div>

      <div
        v-if="files && files.length"
        class="mt-5
              d-flex
              align-items-center
              justify-content-between
              px-1
              text-center
            "
      >
        <h5>{{ $t('objects.title') }}</h5>
        <h5>{{ $t('actions') }}</h5>
      </div>

      <div no-body class="p-1" v-for="(file, index) in files" :key="index">
        <div class="d-flex align-items-center justify-content-between">
          <p class="file-text mr-2 mb-0 file-name">
            {{ file.name ? file.name : file.title }}
          </p>
          <div class="d-flex">
            <b-button
              v-if="editItem && editItem.id"
              variant="success"
              size="sm"
              @click="setFile(file)"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
            <b-button
              class="mx-50"
              variant="primary"
              size="sm"
              v-if="editItem && editItem.id"
              @click="downloadFile(file.file)"
            >
              <feather-icon icon="DownloadIcon" />
            </b-button>

            <div v-if="files.length > 1">
              <b-button
                v-if="editItem && editItem.id"
                variant="danger"
                size="sm"
                @click="deleteFile(file.id)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>

              <b-button
                v-else
                variant="danger"
                size="sm"
                @click="deleteFile(index)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </div>
          </div>
          <!-- </div> -->
        </div>
      </div>
      <SeeDocModal :document="{ file: targetFile }" />
    </B-Modal>
  </b-modal>
</template>

<script>
  import {
    BFormGroup,
    BFormInput,
    BButton,
    BModal,
    BFormCheckbox,
    BFormDatepicker,
    BRow,
    BCol,
    BFormFile,
    BBadge,
  } from 'bootstrap-vue'
  import SeeDocModal from '@/components/SeeDocModal.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required, integer, length } from '@validations'
  import { mapActions, mapGetters } from 'vuex'
  import VSelect from 'vue-select'
  import Cleave from 'vue-cleave-component'
  import Datepicker from 'vuejs-datepicker'
  import { ru } from 'vuejs-datepicker/dist/locale'

  import moment from 'moment'
  import { getUserData } from '@/auth/utils'

  export default {
    components: {
      BFormGroup,
      BFormInput,
      BButton,
      BModal,
      ValidationProvider,
      ValidationObserver,
      BFormDatepicker,
      Datepicker,
      BFormCheckbox,
      BFormFile,
      BRow,
      BCol,
      VSelect,
      BBadge,
      Cleave,
      SeeDocModal,
    },

    props: {
      editItem: {
        type: Object,
      },
    },

    data() {
      return {
        ru,
        integer,
        isSend: false,
        length,
        required,
        files: [],
        targetFile: {},
        formData: {
          title: '',
          payer: '',
          contractor: '',
          payment_purpose: '',
          settlement_account: '',
          bill_type: '',
          city: '',
          start_date: '',
          end_date: '',
          payment_day: null,
          monthly_limit: 0,
          number: '',
          is_active: true,
          is_auto_confirmable: false,
        },

        file: null,

        moneyOptions: {
          numeral: true,
          // prefix: ' UZS',
          rawValueTrimPrefix: true,
          numeralPositiveOnly: true,
          tailPrefix: true,
          noImmediatePrefix: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      }
    },

    computed: {
      ...mapGetters('knowledgeBase', [
        'GET_CONTRACTORS_LIST',
        'GET_PAYERS_LIST',
        'GET_CITIES_LIST',
        'GET_SETTLEMENT_ACCOUNT_BY_CONTRACTOR',
        'GET_BILL_TYPE_LIST',
      ]),

      ...mapGetters('taskManager', ['GET_ATTACHMENTS']),

      getTitle() {
        return this.editItem?.id ? 'Редактировать ' : 'Добавить'
      },

      typeofFile() {
        return typeof this.file
      },

      isFileInput() {
        if (this.file?.title) {
          return false
        } else {
          return true
        }
      },
    },

    updated() {
      console.log(this.formData.start_date, this.formData.end_date)
    },

    mounted() {
      this.FETCH_PAYERS_LIST({
        user_id: getUserData().id,
      })
      this.FETCH_CITIES_LIST()
      this.FETCH_CONTRACTORS_LIST()
      this.FETCH_BILL_TYPE_LIST()
    },

    methods: {
      ...mapActions('knowledgeBase', [
        'FETCH_CONTRACTORS_LIST',
        'FETCH_CITIES_LIST',
        'FETCH_BILL_TYPE_LIST',
        'FETCH_PAYERS_LIST',
        'FETCH_SETTLEMENT_ACCOUNT_BY_CONTRACTOR',
      ]),

      ...mapActions('taskManager', [
        'CREATE_CONTRACT',
        'UPDATE_CONTRACT',
        'FETCH_ATTACHMENT_FILES',
        'DELETE_ATTACHMENT_FILE',
        'UPLOAD_ATTACHMENT_FILE',
      ]),

      downloadFile(url) {
        // console.log('URL', url)
        fetch(url).then(function(t) {
          return t.blob().then(b => {
            var a = document.createElement('a')
            a.href = URL.createObjectURL(b)
            a.setAttribute('download', 'документ')
            a.click()
          })
        })
      },

      setFile(item) {
        this.targetFile = item.file

        this.$nextTick(() => {
          this.$bvModal.show('see-doc-modal')
        })
      },

      async deleteFile(id) {
        const isSure = await this.$_showAreYouSureModal()
        if (!isSure) {
          return
        }

        if (this.editItem?.id) {
          try {
            await this.DELETE_ATTACHMENT_FILE(id)

            this.$_successToast(this.$t('general.successfully_removed'))
            await this.FETCH_ATTACHMENT_FILES({
              content_type: this.editItem.content_type,
              object_id: this.editItem.id,
            })

            this.files = [...this.GET_ATTACHMENTS.results]
          } catch (error) {
            console.log('error', error)
            this.$_errorToast(this.$t('general.error'))
          }
        } else {
          this.files.splice(id, 1)
        }
      },

      async uploadFile(event) {
        if (this.editItem?.id) {
          const formData = new FormData()
          let userId = getUserData().id
          formData.append('file', event)
          formData.append('content_type', this.editItem.content_type)
          formData.append('object_id', this.editItem.id)
          formData.append('author', userId)

          await this.UPLOAD_ATTACHMENT_FILE(formData)
          this.FETCH_ATTACHMENT_FILES({
            content_type: this.editItem.content_type,
            object_id: this.editItem.id,
          })

          await this.FETCH_ATTACHMENT_FILES({
            content_type: this.editItem.content_type,
            object_id: this.editItem.id,
          })

          this.files = [...this.GET_ATTACHMENTS.results]
        } else {
          this.files.push(event)
        }

        console.log('event', event)
      },

      fetchSettlementAccount(contractor_id) {
        if (contractor_id) {
          this.FETCH_SETTLEMENT_ACCOUNT_BY_CONTRACTOR({
            contractor_id: contractor_id,
          })
        } else {
          this.formData.settlement_account = ''
        }
      },

      customFormatter(date) {
        if (date) {
          return moment(date).format('YYYY-MM-DD')
        } else {
          return null
        }
      },

      hideModal() {
        this.isSend = true
      },

      async onSubmit(bvModalEvt) {
        bvModalEvt.preventDefault()
        this.isSend = true
        const response = await this.$refs.ContractList.validate()
        if (!response || this.files.length == 0) {
          return
        }

        try {
          if (this.editItem?.id) {
            if (this.formData.payment_day == '')
              this.formData.payment_day = null

            await this.UPDATE_CONTRACT({
              data: this.formData,
              id: this.editItem.id,
            })
          } else {
            if (this.formData.payment_day == null)
              this.formData.payment_day = ''
            let exportData = this.$_toFormData(this.formData)

            for (let f of this.files) exportData.append('file', f)

            await this.CREATE_CONTRACT(exportData)
          }

          this.$emit('refresh')

          this.$nextTick(() => {
            this.$_successToast(this.$t('general.success'))
            this.$bvModal.hide('add-contract-modal')
          })
        } catch (error) {
          this.$_errorToast(this.$t('general.error'))
        }
      },

      async openModal() {
        this.isSend = false
        if (this.editItem.id) {
          const {
            title,
            city,
            contractor,
            start_date,
            end_date,
            payment_day,
            file,
            is_active,
            payer,
            monthly_limit,
            number,
            is_auto_confirmable,
            settlement_account,
            bill_type,
            payment_purpose,
          } = this.editItem

          this.formData.bill_type = bill_type.id
          this.formData.payment_purpose = payment_purpose
          this.formData.title = title
          this.formData.city = city.id
          this.formData.contractor = contractor.id
          await this.fetchSettlementAccount(this.formData.contractor)
          this.formData.settlement_account = settlement_account.id
          this.formData.start_date = start_date
          this.formData.end_date = end_date
          this.formData.payment_day = payment_day
          this.formData.is_active = is_active
          this.formData.is_auto_confirmable = is_auto_confirmable
          this.formData.payer = payer.id
          this.formData.monthly_limit = monthly_limit
          this.formData.number = number
          this.file = { ...file }
          await this.FETCH_ATTACHMENT_FILES({
            content_type: this.editItem.content_type,
            object_id: this.editItem.id,
          })

          this.files = [...this.GET_ATTACHMENTS.results]
          console.log('this.files', this.files)
          console.log('FILES', this.GET_ATTACHMENTS)
        } else {
          this.formData.settlement_account = ''
          this.formData.bill_type = ''
          this.formData.payment_purpose = ''
          this.formData.title = ''
          this.formData.city = ''
          this.formData.contractor = ''
          this.formData.start_date = ''
          this.formData.end_date = ''
          this.formData.payment_day = null
          this.formData.is_active = true
          this.formData.is_auto_confirmable = false
          this.formData.payer = ''
          this.formData.monthly_limit = 0
          this.file = null
          this.formData.number = ''
          this.files = []
        }
      },
    },

    watch: {
      // 'formData.monthly_limit': {
      //   handler(value) {
      //     if (value > 0) this.formData.is_auto_confirmable = true
      //     else this.formData.is_auto_confirmable = false
      //   },
      // },
      files: {
        handler() {
          if (this.files.length > 0) this.isSend = false
        },
      },
    },
  }
</script>

<style scoped>
  #username {
    text-transform: uppercase;
  }
  .file-border {
    border: 1px solid black;
    border-radius: 6px;
  }

  .file-name {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
</style>
