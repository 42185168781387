<template>
  <!-- id="contract-table" -->
  <div>
    <!-- {{ fields }} -->
    <!-- {{ busy }} -->
    <b-table
      :busy="busy"
      class="mt-2 "
      :fields="fields"
      :items="items"
      show-empty
      :empty-text="$t('administration.no_records')"
      responsive
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle" variant="primary"></b-spinner>
        </div>
      </template>
      <template #empty>
        <h4 class="text-center">{{ $t('administration.no_records') }}</h4>
      </template>
      <template #emptyfiltered="scope">
        <h4 class="text-center">{{ scope.emptyFilteredText }}</h4>
      </template>
      <template #head()="props">
        <span>{{ $t(props.label) }}</span>
      </template>

      <template #cell(monthly_limit)="data">
        {{ $_moneyFormatter(data.item.monthly_limit) }}
      </template>

      <template #cell(is_active)="row" class="text-center">
        <b-form-checkbox
          :checked="row.item.is_active"
          name="check-button"
          class="custom-control-primary"
          switch
          inline
          disabled
        >
        </b-form-checkbox>
      </template>

      <template #cell(start_date)="row" class="text-center">
        <div>
          {{ row.item.start_date }}
        </div>
        <div>
          {{ row.item.end_date }}
        </div>
      </template>

      <template #cell(is_auto_confirmable)="row" class="text-center">
        <b-form-checkbox
          :checked="row.item.is_auto_confirmable"
          name="check-button"
          class="custom-control-primary"
          switch
          inline
          disabled
        >
        </b-form-checkbox>
      </template>

      <template #cell(author)="data">
        <b-avatar
          :src="data.item.author.photo"
          v-b-tooltip.hover
          class="pull-up mr-1"
          :title="data.item.author.full_name"
        />
      </template>

      <template #cell(actions)="{ item }">
        <div class="d-flex justify-content-end">
          <b-button
            size="sm"
            variant="info"
            class="mr-50"
            @click="$emit('openFileList', item)"
          >
            <feather-icon icon="FileIcon" />
          </b-button>
          <b-button
            size="sm"
            variant="success"
            class="mr-50"
            @click="$emit('openInfoModal', item)"
          >
            <feather-icon icon="InfoIcon" />
          </b-button>
          <b-button
            variant="warning"
            size="sm"
            class="mr-50"
            @click="$emit('editData', item)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
          <b-button
            size="sm"
            variant="danger"
            @click="$emit('deleteItem', item.id)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import {
  BTable,
  BButton,
  BSpinner,
  BAvatar,
  VBTooltip,
  BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BButton,
    BSpinner,
    BAvatar,
    BFormCheckbox,
    VBTooltip,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    busy: {
      type: Boolean,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {}
  },
}
</script>
<!-- 
<style lang="scss">
#contract-table .tableHeadClass {
  vertical-align: middle;
}

#contract-table .action-head {
  text-align: center;
}
</style> -->
