<template>
  <b-modal
    id="contract-info-modal"
    centered
    title="Информация договор"
    no-close-on-backdrop
    size="lg"
    ok-only
    ok-title="Да"
  >
    <BRow>
      <BCol md="12" class="my-50">
        <div class="d-flex justify-content-center align-items-center ">
          <h5 class="mr-2">
            Наименование:
          </h5>
          <div>
            {{ editItem.title }}
          </div>
        </div>
      </BCol>
      <BCol md="12" class="my-50">
        <div class="d-flex justify-content-center align-items-center ">
          <h5 class="mr-2">
            Номер договора :
          </h5>
          <div>
            {{ editItem.number }}
          </div>
        </div></BCol
      >

      <BCol md="12" class="my-50">
        <div class="d-flex justify-content-center align-items-center ">
          <h5 class="mr-2">
            Плательщик:
          </h5>
          <div>
            {{ editItem.payer.title }}
          </div>
        </div></BCol
      >

      <BCol md="12" class="my-50">
        <div class="d-flex justify-content-center align-items-center ">
          <h5 class="mr-2">
            Контрагенты :
          </h5>
          <div>
            {{ editItem.contractor.title }}
          </div>
        </div></BCol
      >

      <BCol md="12" class="my-50">
        <div class="d-flex justify-content-center align-items-center ">
          <h5 class="mr-2">
            Расчетный счет контрагента:
          </h5>
          <div>
            {{ editItem.settlement_account.settlement_account }}
          </div>
        </div></BCol
      >

      <BCol md="12" class="my-50">
        <div class="d-flex justify-content-center align-items-center ">
          <h5 class="mr-2">
            Город :
          </h5>
          <div>
            {{ editItem.city.title }}
          </div>
        </div></BCol
      >

      <BCol md="12" class="my-50">
        <div class="d-flex justify-content-center align-items-center ">
          <h5 class="mr-2">
            Активность :
          </h5>
          <div>
            <BBadge
              variant="light-success"
              class="p-25"
              v-if="editItem.is_active"
            >
              <!-- {{ editItem.is_auto_confirmable }} -->
              <feather-icon icon="CheckSquareIcon" size="30" />
            </BBadge>

            <BBadge variant="light-danger" class="p-25" v-else>
              <!-- {{ editItem.is_auto_confirmable }} -->
              <feather-icon icon="XSquareIcon" size="30" />
            </BBadge>
          </div></div
      ></BCol>

      <BCol md="12" class="my-50">
        <div class="d-flex justify-content-center align-items-center ">
          <h5 class="mr-2">
            Автоодобрение :
          </h5>
          <div>
            <BBadge
              variant="light-success"
              class="p-25"
              v-if="editItem.is_auto_confirmable"
            >
              <feather-icon icon="CheckSquareIcon" size="30" />
            </BBadge>

            <BBadge variant="light-danger" class="p-25" v-else>
              <feather-icon icon="XSquareIcon" size="30" />
            </BBadge>
          </div></div
      ></BCol>

      <BCol md="12" class="my-50">
        <div class="d-flex justify-content-center align-items-center ">
          <h5 class="mr-2">
            Дата начала/окончания :
          </h5>
          <div>{{ editItem.start_date }}/{{ editItem.end_date }}</div>
        </div></BCol
      >

      <BCol md="12" class="my-50">
        <div class="d-flex justify-content-center align-items-center ">
          <h5 class="mr-2">
            Дата оплаты :
          </h5>
          <div>
            {{ editItem.payment_day }}
          </div>
        </div></BCol
      >

      <BCol md="12" class="my-50">
        <div class="d-flex justify-content-center align-items-center ">
          <h5 class="mr-2">
            Месячный лимит :
          </h5>
          <div>
            {{ editItem.monthly_limit }}
          </div>
        </div></BCol
      >

      <BCol md="12" class="my-50">
        <div class="d-flex justify-content-center align-items-center ">
          <h5 class="mr-2">
            Автор :
          </h5>
          <div>
            {{ editItem.author.full_name }}
          </div>
        </div></BCol
      >
    </BRow>
  </b-modal>
</template>

<script>
import { BModal, BRow, BCol, BBadge } from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BBadge,
  },

  props: {
    editItem: {
      type: Object,
   
    },
  },
}
</script>


<style scoped>
h5 {
  padding: 0;
  margin: 0;
}
</style>