<template>
  <div>
    <BRow>
      <BCol md="2">
        <AppSearchInput
          @callback="changeFilter('search', $event)"
          :placeholder="$t('search')"
          :value="params.search"
        />
      </BCol>
      <BCol md="3">
        <b-form-group>
          <label for=""> Плательщик :</label>
          <v-select
            label="title"
            placeholder="Выберите плательщик"
            v-model="params.payer"
            :options="GET_PAYERS_LIST"
            @input="changeFilter('payer', $event)"
            :reduce="p => p.id"
          />
        </b-form-group>
      </BCol>
      <BCol md="3">
        <b-form-group>
          <label for=""> Контрагенты :</label>
          <v-select
            label="title"
            placeholder="Выберите контрагенты"
            v-model="params.contractor"
            :options="GET_CONTRACTORS_LIST"
            @input="changeFilter('contractor', $event)"
            :reduce="p => p.id"
          />
        </b-form-group>
      </BCol>
      <BCol md="4" class="d-flex justify-content-end  align-items-center">
        <b-button variant="primary" @click="addData" class="mt-2">
          <feather-icon icon="PlusIcon" />
          {{ $t('add') }}
        </b-button>
      </BCol>
    </BRow>
    <b-card class="mt-1">
      <ContractTable
        :busy="busy"
        :fields="fields"
        :items="GET_CONTRACTS.results"
        @deleteItem="deleteItem"
        @downloadFile="downloadFile"
        @openFileList="openFileList"
        @editData="editData"
        @openInfoModal="openInfoModal"
      />
    </b-card>

    <b-card>
      <TablePagination
        :count="GET_CONTRACTS.count"
        @changePaginationValue="changePaginationValue"
      />
    </b-card>

    <ContractModal :editItem="editItem" @refresh="refresh" />
    <ContractFileListModal :propsItem="fileListItem" />
    <ContractInfoModal :editItem="infoItem" />
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BPagination,
  BInputGroupPrepend,
  BInputGroup,
  BTable,
  BButton,
  BFormSelect,
  BCard,
  BAvatar,
  BRow,
  BCol,
  BSpinner,
  VBTooltip,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import ContractFilter from './components/ContractFilter.vue'
import ContractModal from './components/ContractModal.vue'
import ContractFileListModal from './components/ContractFileListModal.vue'
import ContractInfoModal from './components/ContractInfoModal.vue'
import ContractTable from './components/ContractTable.vue'
import { getUserData } from '@/auth/utils'
import TablePagination from '@/components/TablePagination.vue'
import AppSearchInput from '@/components/AppSearchInput.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
    BSpinner,
    BRow,
    BCol,
    BFormSelect,
    BInputGroupPrepend,
    BInputGroup,
    BCard,
    BAvatar,
    AppSearchInput,
    vSelect,
    ContractModal,
    ContractFilter,
    ContractFileListModal,
    ContractInfoModal,
    ContractTable,
    TablePagination,
  },

  data() {
    return {
      editItem: {},
      infoItem: {
        payer: {},
        contractor: {},
        author: {},
        city: {},
        settlement_account: {},
      },
      params: {
        page: 1,
        page_size: 10,
        search: '',
        payer: null,
        contractor: null,
      },
      busy: false,

      fileListItem: {},
      fields: [
        {
          label: 'Наименование ',
          key: 'title',
          thClass: 'tableHeadClass',
        },

        {
          label: 'Номер договора ',
          key: 'number',
          thClass: 'tableHeadClass',
        },

        {
          label: 'Плательщик ',
          key: 'payer.title',
          thClass: 'tableHeadClass',
        },
        {
          label: 'Контрагенты ',
          key: 'contractor.title',
          thClass: 'tableHeadClass',
        },
        {
          label: 'Город',
          key: 'city.title',
          thClass: 'tableHeadClass',
        },
        {
          label: this.$t('client.activity'),
          key: 'is_active',
          thClass: 'tableHeadClass',
        },
        {
          label: 'Автоодобрение',
          key: 'is_auto_confirmable',
          thClass: 'tableHeadClass',
        },
        {
          label: 'Дата начала/окончания',
          key: 'start_date',
          thClass: 'tableHeadClass',
          tdClass: 'width-200',
        },
        {
          label: 'Дата оплаты',
          key: 'payment_day',
          thClass: 'tableHeadClass',
        },
        {
          label: 'Месячный лимит',
          key: 'monthly_limit',
          thClass: 'tableHeadClass',
        },
        {
          label: 'Автор',
          key: 'author',
          thClass: 'tableHeadClass',
        },
        {
          label: this.$t('actions'),
          key: 'actions',
          thClass: 'tableHeadClass action-head',
          tdClass: 'width-150',
        },
      ],
    }
  },

  computed: {
    ...mapGetters('taskManager', ['GET_CONTRACTS']),
    ...mapGetters('knowledgeBase', [
      'GET_BILL_TYPE_LIST',
      'GET_PAYERS_LIST',
      'GET_CONTRACTORS_LIST',
    ]),
    ...mapGetters('knowledgeBase', [
      'GET_BILL_TYPE_LIST',
      'GET_PAYERS_LIST',
      'GET_CONTRACTORS_LIST',
    ]),
  },
  mounted() {
    this.refresh()
    this.FETCH_CONTRACTORS_LIST()
    this.FETCH_PAYERS_LIST({ user_id: getUserData().id })
  },
  methods: {
    ...mapActions('taskManager', ['FETCH_CONTRACTS', 'DELETE_CONTRACT']),
    ...mapActions('knowledgeBase', [
      'FETCH_BILL_TYPE_LIST',
      'FETCH_PAYERS_LIST',
      'FETCH_CONTRACTORS_LIST',
    ]),
    ...mapActions('knowledgeBase', [
      'FETCH_BILL_TYPE_LIST',
      'FETCH_PAYERS_LIST',
      'FETCH_CONTRACTORS_LIST',
    ]),

    async refresh() {
      const sessionFilter = this.$_getFilter()

      if (sessionFilter) {
        this.params = { ...sessionFilter }
      }

      try {
        this.busy = true
        await this.FETCH_CONTRACTS(this.params)
      } catch (error) {
        this.$_errorToast(this.$t('error'))
      }
      this.busy = false
    },

    downloadFile(url) {
      fetch(url).then(function(t) {
        return t.blob().then(b => {
          var a = document.createElement('a')
          a.href = URL.createObjectURL(b)
          a.setAttribute('download', 'документ')
          a.click()
        })
      })
    },

    onSearch(text) {
      this.params.search = text
      this.params.page = 1
      this.refresh()
    },

    changePaginationValue({ page, page_size }) {
      this.params.page = page
      this.params.page_size = page_size
      this.refresh()
    },

    openInfoModal(item) {
      this.infoItem = item

      this.$nextTick(() => {
        this.$bvModal.show('contract-info-modal')
      })
    },

    editData(item) {
      this.editItem = item

      this.$nextTick(() => {
        this.$bvModal.show('add-contract-modal')
      })
    },

    openFileList(item) {
      this.fileListItem = item

      this.$nextTick(() => {
        this.$bvModal.show('contract-modal')
      })
    },
    addData() {
      this.editItem = {}

      this.$nextTick(() => {
        this.$bvModal.show('add-contract-modal')
      })
    },

    changeFilter(type, value) {
      this.params.page = 1

      if (type === 'created_date') {
        this.params.from_date = value?.start || ''
        this.params.to_date = value?.end || ''
      } else this.params[type] = value

      this.$_saveFilter(this.params)

      this.refresh()
    },

    deleteItem(id) {
      this.$_showAreYouSureModal().then(value => {
        if (value) {
          if (this.GET_CONTRACTS.results.length === 1) {
            if (this.params.page > 1) --this.params.page
          }
          this.DELETE_CONTRACT(id)
            .then(() => {
              this.refresh()
              this.$_successToast(this.$t('general.success'))
              this.params.page = 1
            })
            .catch(() => {
              this.$_errorToast(this.$t('error'))
            })
        }
      })
    },
  },
}
</script>


<style scoped>
.vs--single {
  background: white;
  border-radius: 5px;
}
.vs--single::placeholder {
  color: blue;
}
</style>
