<template>
  <b-modal
    id="see-doc-modal"
    centered
    no-close-on-backdrop
    size="lg"
    ok-only
    ok-title="Да"
    ok-variant="success"
  >
    <div class="doc-viewer">
    
      <AllDocViewer :src="document.file" />
    </div>
  </b-modal>
</template>

<script>
import { BButton, BModal } from 'bootstrap-vue'
import AllDocViewer from '@/components/AllDocViewer.vue'

export default {
  components: {
    BButton,
    BModal,
    AllDocViewer,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>
[dir] .modal-body {
  padding: 0;
}

.doc-viewer {
  height: 70vh;
}
</style>
